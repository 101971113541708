import React from "react"

import Layout from "components/Layout"
import SubpageHero from "components/SubpageHero"

import { Content } from "page_components/contact-confirmation"

const ContactConfirmation = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Thank you for contact",
    }}
  >
    <SubpageHero title="Dziękujemy" description="za wysłanie formularza" />
    <Content />
  </Layout>
)

export default ContactConfirmation

import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

import Section from "components/Section"

const Content = () => {
  const { t } = useLocale()

  return (
    <Section className="contact-confirmation-content">
      <Link className="h3" to={t("/")}>
        {t("powrót na stronę główną")}
      </Link>
    </Section>
  )
}

export default Content
